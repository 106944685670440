import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import useTranslation from '../../../localization/useTranslation'
import { AUDIENCE_AVAILABLE_STATUS } from '../../../constants/audienceAnalysis'
import { USER_INDEXES } from '../../../constants/appSettings'
import {
  userGrantSelector,
  areAvailableAudienceTokensSelector,
  selectedProfileSelector,
  isUserAuthenticatedSelector,
} from '../../../redux/selectors'

import AudienceAnalysisCharts from './AudienceAnalysisCharts'
import AudienceAnalysisChartsYT from './AudienceAnalysisChartsYT'
import AudienceReportLocked from './AudienceReportLocked'
import AudienceReportUnavailable from './AudienceReportUnavailable'
import SmartMediaValue from './smartMediaValue/SmartMediaValue'

import { AudienceAnalysisContainer, CurrencySelect } from './styles'
import {
  SectionTitleWrapper,
  SectionTitleName,
  SectionTitleBorder,
} from '../commonProfilePageStyles'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { IconButton, Stack, Typography } from '@mui/material'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'
import { ModalComponent } from '../../../new-ui/components/modal/ModalComponent'
import { AudienceAnalyticsCpmSettings } from './AudienceAnalyticsCpmSettings'
import { adjustCPMValues } from '../../../redux/ducks/profileSelectedDucks'
import GradientTransparentButton from '../../common/buttons/GradientTransparentButton'
import UnavailableProfileSection from '../../common/unavailableProfileSection'

const AudienceAnalysisMediator = ({ profile }) => {
  const { labelStrings, currentLang } = useTranslation()
  const dispatch = useDispatch()

  const {
    audienceAnalysis,
    audienceAnalysisLikers,
    _id: userId,
    username: userName,
    _index: platform,
    followers: followersNumber,
    audienceAvailable: audienceAvailableStatus,
    isProfileTokenValid,
    cpms,
  } = profile

  const { cpmAdjustments } = useSelector(selectedProfileSelector)
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)

  const [openSettings, setOpenSettings] = useState(false)
  const [showLikers, setShowLikers] = useState(false) // toggle between followers and likers

  const isCpms = !!cpms ? !!Object.values(cpms)?.filter(Boolean)?.length : false

  const [currentCurrency, setCurrentCurrency] = useState(null)
  const [currencyFromStorage, setCurrencyFromStorage] = useLocalStorage('currency', currentCurrency)

  const currencyObj = useMemo(
    () => [
      { value: 'usd', label: 'USD' },
      { value: 'eur', label: 'EUR' },
      { value: 'gbp', label: 'GBP' },
    ],
    []
  )

  const changeCurrency = value => {
    setCurrentCurrency(value)
    setCurrencyFromStorage(value)
  }

  useEffect(() => {
    const getCurrency = currentLang === 'de' ? 'eur' : 'usd'
    const initialCurrency = currencyFromStorage ? currencyFromStorage : getCurrency
    changeCurrency(initialCurrency)
  }, [currencyFromStorage, currentLang])

  const isTokensAvailable = useSelector(areAvailableAudienceTokensSelector)
  const { in_trial: isTrial } = useSelector(userGrantSelector)

  const isLockedInstant = audienceAvailableStatus === AUDIENCE_AVAILABLE_STATUS.lockedInstant
  const isLockedDelayed = audienceAvailableStatus === AUDIENCE_AVAILABLE_STATUS.lockedDelayed
  const isUnlockedRequested =
    audienceAvailableStatus === AUDIENCE_AVAILABLE_STATUS.unlockedRequested
  const isIGReportImpossible =
    platform === USER_INDEXES.instagram &&
    audienceAvailableStatus === AUDIENCE_AVAILABLE_STATUS.reportBlocked
  const isYTReportImpossible =
    platform === USER_INDEXES.youtube &&
    audienceAvailableStatus === AUDIENCE_AVAILABLE_STATUS.reportImpossible

  let sectionTitle = labelStrings.audienceAnalysis
  if (isCpms) {
    sectionTitle = labelStrings.audienceAnalysisNSmartMediaValue
  }

  const submitAdjustCPMValues = payload => {
    // @ts-ignore
    dispatch(adjustCPMValues({ ...payload, userId }))
    setOpenSettings(false)
  }

  return (
    <>
      <SectionTitleWrapper>
        <SectionTitleName platform={platform}>{sectionTitle}</SectionTitleName>
        <SectionTitleBorder />
        {isCpms && (
          <CurrencySelect
            id="country-select"
            disabled={false}
            value={currencyObj.find(el => el.value === currentCurrency)}
            options={currencyObj}
            isValid={true}
            selectHeight={35}
            onChange={({ value }) => changeCurrency(value)}
            escapeClearsValue={true}
            placeholder={labelStrings.selectCurrency}
          />
        )}
        <IconButton
          sx={{ ml: 1 }}
          disabled={isLockedInstant || isLockedDelayed || isIGReportImpossible}
          onClick={() => setOpenSettings(!openSettings)}
        >
          <SettingsRoundedIcon />
        </IconButton>
      </SectionTitleWrapper>

      <AudienceAnalysisContainer>
        {(() => {
          /*
          // Example check for user login if needed
          // if (!isUserAuthenticated) {
          //   return (
          //     <UnavailableProfileSection
          //       // @ts-ignore
          //       unavailableSectionLabel={labelStrings.toSeeAudienceAnalysis}
          //       linkToFaqText={labelStrings.whatAreTheAudienceAnalysis}
          //       platform={platform}
          //     />
          //   )
          // }
          */

          if (isLockedInstant || isLockedDelayed || isIGReportImpossible) {
            return (
              <AudienceReportLocked
                userId={userId}
                userName={userName}
                platform={platform}
                isTokensAvailable={isTokensAvailable}
                isProfileTokenValid={isProfileTokenValid}
                isTrial={isTrial}
                isLockedInstant={isLockedInstant}
                isBlockedReport={isIGReportImpossible}
              />
            )
          }

          if (isUnlockedRequested || !audienceAnalysis || isYTReportImpossible) {
            return (
              <AudienceReportUnavailable
                userName={userName}
                userId={userId}
                platform={platform}
                isReportImpossible={isYTReportImpossible}
              />
            )
          }

          // Show actual charts
          return (
            <>
              {isCpms && (
                <SmartMediaValue platform={platform} cpms={cpms} currency={currentCurrency} />
              )}
              <Stack spacing={1.5} sx={{ mb: 0.5 }}>
                <Typography
                  variant="h3"
                  style={{
                    fontFamily: "'Inter', sans-serif",
                    fontSize: '18px',
                    lineHeight: '20px',
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    color: '#784E4E',
                    marginTop: '20px',
                  }}
                >
                  {labelStrings.audienceReport}
                </Typography>
                {audienceAnalysis && platform === USER_INDEXES.instagram && (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <GradientTransparentButton
                      onClick={() => setShowLikers(false)}
                      isActive={!showLikers}
                    >
                      Followers
                    </GradientTransparentButton>
                    {platform !== USER_INDEXES.tiktok && (
                      <GradientTransparentButton
                        onClick={() => setShowLikers(true)}
                        isActive={showLikers}
                        isDisabled={!audienceAnalysisLikers}
                        userIndex={platform}
                      >
                        Likers
                      </GradientTransparentButton>
                    )}
                  </Stack>
                )}
              </Stack>

              {!showLikers ? (
                // Followers Report
                platform === USER_INDEXES.youtube ? (
                  <AudienceAnalysisChartsYT
                    audienceAnalysis={audienceAnalysis}
                    platform={platform}
                    followersNumber={followersNumber}
                    hasSmartMediaValue={isCpms}
                    isDisabledAnalysis={!audienceAnalysis}
                  />
                ) : (
                  <AudienceAnalysisCharts
                    audienceAnalysis={audienceAnalysis}
                    platform={platform}
                    followersNumber={followersNumber}
                    hasSmartMediaValue={isCpms}
                    hasAudienceTypeAndMentionsCharts={platform === USER_INDEXES.instagram}
                    isDisabledAnalysis={!audienceAnalysis}
                  />
                )
              ) : (
                // Likers Report
                <AudienceAnalysisCharts
                  audienceAnalysis={audienceAnalysisLikers}
                  platform={platform}
                  followersNumber={followersNumber}
                  hasSmartMediaValue={isCpms}
                  hasAudienceTypeAndMentionsCharts={platform === USER_INDEXES.instagram}
                  isLikersReport={showLikers}
                />
              )}
            </>
          )
        })()}
      </AudienceAnalysisContainer>

      {/*
        This small note will appear *always*, regardless of
        whether the audience report is locked/unlocked.
      */}
      <Typography
        variant="caption"
        sx={{
          display: 'block',
          mt: 1.5,
          color: '#784E4E',
          fontFamily: "'Inter', sans-serif",
          fontSize: '12px',
          lineHeight: '16px',
        }}
      >
        {labelStrings.unlockedReportsSharedText}
      </Typography>

      <ModalComponent
        isOpenModal={openSettings}
        closeModal={() => setOpenSettings(false)}
        title={labelStrings.cpmSettings.titleModal}
        contentWidth="700px"
        contentHeight="600px"
        isCenterTitle
      >
        <AudienceAnalyticsCpmSettings
          onSubmit={submitAdjustCPMValues}
          initialCpmAdjustments={cpmAdjustments}
          currentCurrency={currentCurrency}
        />
      </ModalComponent>
    </>
  )
}

AudienceAnalysisMediator.propTypes = {
  profile: PropTypes.object.isRequired,
}

export default AudienceAnalysisMediator
