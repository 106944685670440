import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { USER_INDEXES } from '../../../constants/appSettings'

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const TabContentWrapper = styled.div`
  user-select: none;

  ${props => props.isDisabled && props.theme.disabled};

  ${props => {
    let color = props.theme.colors.mauve
    let colorActive = props.theme.colors.white

    if (props.platform === USER_INDEXES.tiktok) {
      color = props.theme.colors.aquaLight
    }

    if (props.isActive) return `color: ${colorActive};`
    return `color: ${color};`
  }}
`

export const DisabledTipWrapper = styled.div`
  font-weight: normal;
  text-transform: none;
`

export const TabWrapper = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  padding: 3px 7px;
  border-radius: 9px;
  cursor: pointer;
  text-transform: uppercase;

  ${props => props.isDisabled && `pointer-events: none;`}

  ${props => {
    let bgDisabled = props.theme.colors.mediumBrown
    let bgActive = props.theme.gradients.main
    let borderColor = props.theme.colors.mediumBrown

    switch (props.platform) {
      case USER_INDEXES.tiktok:
        bgDisabled = props.theme.colors.aquaUltraLight
        bgActive = props.theme.gradients.mainTiktok
        borderColor = props.theme.colors.aquaLight
        break
      default:
        break
    }

    if (props.isActive) {
      return `
        background: ${bgActive};
        padding: 4px 8px;
      `
    }
    return `
      border: 1px solid ${borderColor};
      background: transparent;
    `
  }}

  &:hover {
    ${props => {
      if (props.isDisabled) return

      let bgHover = props.theme.gradients.main
      if (props.platform === USER_INDEXES.tiktok) {
        bgHover = props.theme.gradients.mainTiktok
      }

      return (
        !props.isActive &&
        `
        ${TabContentWrapper} {
          color: ${props.theme.colors.white};
        }

        background: ${bgHover};
        padding: 4px 8px;
        border: none;
      `
      )
    }}
  }

  & + & {
    margin-left: 15px;
  }
`

export const StyledSocialPlatformTabs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 450px;
  width: fit-content;
  height: 40px;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.mauve};
  margin: 0 auto;
  padding: 4px 5px;
`

export const SocialPlatformTabContent = styled.div`
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: ${props => (props.is_active ? props.theme.colors.brown : props.theme.colors.mediumBrown)};
  display: flex;
  align-items: center;

  span {
    margin: 0;
  }

  svg {
    margin-right: 6px;
    margin-bottom: 0;

    path {
      fill: ${props =>
        props.is_active ? props.theme.colors.brown : props.theme.colors.mediumBrown};
    }
  }
`

export const SocialPlatformTab = styled(({ platform, isActive, disabled, ...props }) => <Link {...props} />)`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 5px;
  text-transform: capitalize;
  height: 32px;
  min-height: auto;

  ${props =>
    props.isActive &&
    `
    border: none;
    pointer-events: none;
    background-color: ${props.theme.colors.white};
    box-shadow: 0px 0px 4px -2px #784E4E;
  `}

  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: 0.5;
  `}

  &:hover {
    text-decoration: none;

    ${props =>
      !props.isActive &&
      `
        border: none;
    `}

    ${SocialPlatformTabContent} {
      color: ${props => props.theme.colors.offWhite};

      svg {
        path {
          fill: ${props => props.theme.colors.offWhite};
        }
      }
    }
  }

  & + & {
    margin-left: 15px;
  }
`

export const SocialTabsWrapper = styled.div`
  ${props => props.theme.flex.centerAll}
  justify-content: space-between;
  min-height: 50px;
  padding: 0 7% 0 4%;
  ${props => props.isDisabled && props.theme.disabled}
`

export const TabDisabled = styled.div``

export const WidthMaxWrapper = styled.div`
  width: ${props => (props.isSearchContent ? '100%' : 'auto')};
`
