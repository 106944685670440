import React, { memo } from "react"
import PropTypes from "prop-types"
import { SOCIAL_PLATFORMS_NAMES } from "../../../../constants/appSettings"
import { searchContentTypes } from "../../../../constants/search"
import { Stack } from "@mui/material"
import { CreatorSearchParams } from "./sections/CreatorSearchParams"
import { OtherSettingsSearchParams } from "./sections/OtherSettingsSearchParams"
import { AudienceSearchParams } from "./sections/AudienceSearchParams"
import { ContentCreatorSearchParams } from "./sections/ContentCreatorSearchParams"

const SearchbarMediator = ({
  query,
  handlersMap,
  suggestionsKeywords,
  selectedPlatform,
  permissions,
  availableCountriesCities,
  userindex,
  grants,
  searchType,
  currentOpenSearchParam,
  setCurrentOpenSearchParam
}) => {
  // const { labelStrings } = useTranslation()
  const isNotContent = searchType !== searchContentTypes.CONTENT

  return (
    <Stack sx={{ mt: 5 }}>
      <Stack sx={{ backgroundColor: "#FFF1F1" }} spacing={5}>
        <CreatorSearchParams
          userIndex={userindex}
          query={query}
          permissions={permissions}
          selectedPlatform={selectedPlatform}
          handlersMap={handlersMap}
          availableCountriesCities={availableCountriesCities}
          suggestionsKeywords={suggestionsKeywords}
          searchType={searchType}
          currentOpenSearchParam={currentOpenSearchParam}
          setCurrentOpenSearchParam={setCurrentOpenSearchParam}
        />

        {isNotContent &&
            <AudienceSearchParams
              query={query}
              permissions={permissions}
              selectedPlatform={selectedPlatform}
              handlersMap={handlersMap}
              availableCountriesCities={availableCountriesCities}
              suggestionsKeywords={suggestionsKeywords}
              searchType={searchType}
              currentOpenSearchParam={currentOpenSearchParam}
              setCurrentOpenSearchParam={setCurrentOpenSearchParam}
            />
        }

        {!isNotContent && (
          <ContentCreatorSearchParams
            query={query}
            permissions={permissions}
            selectedPlatform={selectedPlatform}
            handlersMap={handlersMap}
            availableCountriesCities={availableCountriesCities}
            suggestionsKeywords={suggestionsKeywords}
            searchType={searchType}
            currentOpenSearchParam={currentOpenSearchParam}
            setCurrentOpenSearchParam={setCurrentOpenSearchParam}
          />
        )}

        {isNotContent && (
          <OtherSettingsSearchParams
            query={query}
            permissions={permissions}
            selectedPlatform={selectedPlatform}
            handlersMap={handlersMap}
            availableCountriesCities={availableCountriesCities}
            suggestionsKeywords={suggestionsKeywords}
            searchType={searchType}
            currentOpenSearchParam={currentOpenSearchParam}
            setCurrentOpenSearchParam={setCurrentOpenSearchParam}
          />
        )}
      </Stack>
    </Stack>
  )
}

SearchbarMediator.propTypes = {
  selectedPlatform: PropTypes.string,
  query: PropTypes.object.isRequired,
  handlersMap: PropTypes.object.isRequired,
  citiesForCountry: PropTypes.array,
  suggestionsKeywords: PropTypes.array,
  permissions: PropTypes.object,
  availableCountriesCities: PropTypes.object,
  userindex: PropTypes.string,
  grants: PropTypes.object,
  searchType: PropTypes.string,
  currentOpenSearchParam: PropTypes.string,
  setCurrentOpenSearchParam: PropTypes.func
}

export default memo(SearchbarMediator)
