import React from 'react'
import PropTypes from 'prop-types'

import useTranslation from '../../../localization/useTranslation'
import { USER_INDEXES, SOCIAL_PLATFORMS_NAMES } from '../../../constants/appSettings'

import { TikTokIcon, InstagramIcon } from '../icons/icons'
import { StyledSocialPlatformTabs, SocialPlatformTab, SocialPlatformTabContent } from './tabsStyles'
import { YouTubePlatformIcon } from '../icons'
import { useSelector } from 'react-redux'
import { isUserAuthenticatedSelector } from '../../../redux/selectors'

const SocialPlatformTabs = ({ currentPlatform = '', profilesRefs }) => {
  const { labelStrings } = useTranslation()
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)

  const profiles = [
    {
      platform: USER_INDEXES.instagram,
      name: SOCIAL_PLATFORMS_NAMES.instagram.prettyName,
      Icon: InstagramIcon,
    },
    {
      platform: USER_INDEXES.tiktok,
      name: SOCIAL_PLATFORMS_NAMES.tiktok.prettyName,
      Icon: TikTokIcon,
    },
    {
      platform: USER_INDEXES.youtube,
      name: SOCIAL_PLATFORMS_NAMES.youtube.prettyName,
      Icon: YouTubePlatformIcon,
    },
  ]

  return (
    <StyledSocialPlatformTabs>
      {profiles?.map(({ platform, name, Icon }) => {
        const ref = profilesRefs[platform]
        const isActive = currentPlatform === platform
        const isRendered = currentPlatform === platform || !!ref
        const label = `${name} ${labelStrings.profile}`
        const to = ref ? `/profile/${ref}` : ''

        return (
          isRendered && (
            <SocialPlatformTab
              key={platform}
              to={to}
              platform={platform}
              isActive={isActive}
              target="_blank"
              disabled={!isUserAuthenticated}
            >
              <SocialPlatformTabContent is_active={isActive}>
                <Icon />
                {label}
              </SocialPlatformTabContent>
            </SocialPlatformTab>
          )
        )
      })}
    </StyledSocialPlatformTabs>
  )
}

SocialPlatformTabs.propTypes = {
  currentPlatform: PropTypes.oneOf(Object.values(USER_INDEXES)).isRequired,
  profilesRefs: PropTypes.shape({
    [USER_INDEXES.instagram]: PropTypes.string,
    [USER_INDEXES.tiktok]: PropTypes.string,
    [USER_INDEXES.youtube]: PropTypes.string,
  }),
}

export default SocialPlatformTabs
