import React, { useState } from 'react'
import { Box, Grid, Typography, Stack, Button, Modal, InputAdornment } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import FormProvider, { RHFTextField } from '../../../components/hook-form'
import { PublishedItem } from '../../../components/carousel/published-content/PublishedItem'
import useTranslation from '../../../../localization/useTranslation'

export const EditTextFields = props => {
  const {
    onSubmit,
    editItem,
    handleSubmit,
    methods,
    isSubmitting,
    onRemoveContentFromTracking,
    filteredCustomFieldsByContent,
    getValues,
  } = props
  const { labelStrings } = useTranslation()
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const { caption, likes, comments, shares, foundPhrases, label } = getValues()

  const customFields = filteredCustomFieldsByContent?.map(field => (
    <RHFTextField
      key={field.customFieldId}
      inputType="number"
      name={field?.customFieldId}
      label={field?.customFieldName}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">{field?.metricName?.toUpperCase()}</InputAdornment>
        ),
      }}
      disabled={false}
    />
  ))

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} sx={{ pt: 1 }}>
          <Grid item xs={12} md={3.5}>
            <Box sx={{ paddingBottom: 3 }}>
              {editItem && <PublishedItem item={editItem} isNotWidget isEdit />}
              {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <RHFSwitch name="nonOrgReachSwitch" labelPlacement="start" label="" sx={{ m: 0 }} />
                <Box sx={{ maxWidth: '215px' }}>
                  <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                    Non-organic Reach
                  </Typography>
                  <Typography sx={{ fontSize: '14px' }}>
                    Track non-organic reach for that post (e.g., reach via sponsored content)
                  </Typography>
                </Box>
              </Box> */}
            </Box>
          </Grid>

          <Grid item xs={12} md={8.5}>
            <Box sx={{ p: 1, paddingTop: 0 }}>
              <Box
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField inputType="text" name="label" label="Label" />
                <RHFTextField
                  inputType="number"
                  name="reach"
                  label={
                    editItem?.contentType === 'story_group'
                      ? 'Reach for all Stories in this group'
                      : 'Reach (Views)'
                  }
                />
                <RHFTextField
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000000',
                    },
                    backgroundColor: !caption ? '#F4F6F8' : 'inherit',
                    borderRadius: !caption ? '8px' : 'inherit',
                  }}
                  disabled
                  inputType="text"
                  name="caption"
                  multiline
                  rows={4}
                  label="Caption"
                />

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <RHFTextField
                    // sx={{
                    //   '& .MuiInputBase-input.Mui-disabled': {
                    //     WebkitTextFillColor: '#000000',
                    //   },
                    //   backgroundColor: !likes ? '#F4F6F8' : 'inherit',
                    //   borderRadius: !likes ? '8px' : 'inherit',
                    // }}
                    //disabled
                    inputType="number"
                    name="likes"
                    label="Likes"
                  />
                  <RHFTextField
                    // sx={{
                    //   '& .MuiInputBase-input.Mui-disabled': {
                    //     WebkitTextFillColor: '#000000',
                    //   },
                    //   backgroundColor: !comments ? '#F4F6F8' : 'inherit',
                    //   borderRadius: !comments ? '8px' : 'inherit',
                    // }}
                    //disabled
                    inputType="number"
                    name="comments"
                    label="Comments"
                  />
                </Box>

                <RHFTextField
                  sx={{
                    '& .MuiInputBase-input.Mui-disabled': {
                      WebkitTextFillColor: '#000000',
                    },
                    backgroundColor: !foundPhrases?.length ? '#F4F6F8' : 'inherit',
                    borderRadius: !foundPhrases?.length ? '8px' : 'inherit',
                  }}
                  disabled
                  inputType="text"
                  name="foundPhrases"
                  label="Found Phrases"
                />
                <RHFTextField
                  // sx={{
                  //   '& .MuiInputBase-input.Mui-disabled': {
                  //     WebkitTextFillColor: '#000000',
                  //   },
                  //   backgroundColor: !shares ? '#F4F6F8' : 'inherit',
                  //   borderRadius: !shares ? '8px' : 'inherit',
                  // }}
                  // disabled
                  name="shares"
                  label="Shares"
                />
                {customFields}
              </Box>

              {/* <Box sx={{ paddingTop: 3 }}>
                <Typography variant="h6" component="h2">
                  Non-organic metrics
                </Typography>
                <Typography>Please only add metrics for non-organic traffic</Typography>
              </Box>
              <Box
                sx={{ py: 3 }}
                rowGap={3}
                columnGap={2}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                }}
              >
                <RHFTextField inputType="number" name="nonOrgReach" label="Reach (Views)" />
                <RHFTextField inputType="number" name="nonOrgLikes" label="Likes" />
                <RHFTextField inputType="number" name="nonOrgComments" label="Comments" />
                <RHFTextField name="nonOrgShare" label="Share" />
                <RHFTextField name="nonOrgEngagementRate" label="Engagement Rate" />
                <RHFTextField
                  inputType="number"
                  name="nonOrgAmountSpentInEur"
                  label="Amount spent in EUR"
                />
              </Box> */}

              <Stack
                sx={{ mt: 3 }}
                direction="row"
                spacing={1.5}
                alignItems="flex-center"
                justifyContent="flex-end"
              >
                <Button
                  onClick={() => setShowDeleteModal(true)}
                  variant="outlined"
                  sx={{ fontWeight: 'bold' }}
                >
                  Delete Post from Reporting
                </Button>

                <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                  Save Changes
                </LoadingButton>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </FormProvider>
      <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: '8px',
            boxShadow: 24,
            pt: 2,
            px: 4,
            pb: 3,
          }}
        >
          <Typography
            textAlign="center"
            sx={{
              mb: 2,
            }}
          >
            {labelStrings.removeContentPieces}
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
            <Button variant="outlined" onClick={() => setShowDeleteModal(false)}>
              {labelStrings.back}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                onRemoveContentFromTracking()
                setShowDeleteModal(false)
              }}
            >
              {labelStrings.yes}
            </Button>
          </Stack>
        </Box>
      </Modal>
    </>
  )
}
