import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { userCredsSelector } from '../redux/selectors'
import routes from '../routes'
import axios from 'axios'

const HubSpotFormWidget = () => {
  const userCreds = useSelector(userCredsSelector)

  const identifyCustomer = () =>
    new Promise((resolve, reject) => {
      const tryIdentification = () => {
        if (window._hsq && userCreds && userCreds.hubspotToken && userCreds.username) {
          console.log('HubSpot queue available, identifying...')
          window._hsq.push(['identify', { email: userCreds.username }])
          window.hsConversationsSettings = {
            identificationEmail: userCreds.username,
            identificationToken: userCreds.hubspotToken,
          }
          resolve()
        } else {
          setTimeout(tryIdentification, 2000)
        }
      }

      tryIdentification()
    })

  const loadWidget = () => {
    if (window.HubSpotConversations && window.HubSpotConversations.widget) {
      console.log('Loading widget...')
      window.HubSpotConversations.widget.load()
    } else {
      console.log('Widget not available, retrying...')
      setTimeout(loadWidget, 1000)
    }
  }

  const loadScriptFromText = async url => {
    try {
      const response = await axios(url)
      const scriptText = response?.data
      const blob = new Blob([scriptText], { type: 'application/javascript' })
      const scriptURL = URL.createObjectURL(blob)

      return new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.src = scriptURL
        script.async = true
        script.onload = () => {
          // console.log(`Script loaded from ${url}`)
          resolve(script)
          URL.revokeObjectURL(scriptURL)
        }
        script.onerror = e => {
          // console.error(`Script loading error from ${url}`, e)
          URL.revokeObjectURL(scriptURL)
          reject(e)
        }
        document.body.appendChild(script)
      })
    } catch (error) {
      console.error(`Failed to load script from ${url}`, error)
      throw error
    }
  }

  useEffect(() => {
    if (!userCreds || !userCreds.username || !userCreds.hubspotToken) {
      console.log('NO CREDS')
      return
    }

    window.hsConversationsSettings = {
      loadImmediately: false,
      inlineEmbedSelector: '#hubSpot-wrapper',
    }

    loadScriptFromText(routes.collab.api.getJsHsAnalytics)
      .then(() => {
        // console.log('Analytics script executed')
        identifyCustomer()
          .then(() => {
            // console.log('Customer identified, loading chat script...')
            loadScriptFromText(routes.collab.api.getJsHsChat)
              .then(() => {
                // console.log('Chat script executed')
                loadWidget()
              })
              .catch(error => console.error('Error loading chat script:', error))
          })
          .catch(err => console.error('Error identifying customer:', err))
      })
      .catch(error => console.error('Error loading analytics script:', error))

    return () => {}
  }, [userCreds.username, userCreds.hubspotToken])

  return null
}

export default HubSpotFormWidget
