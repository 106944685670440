import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useTranslation from '../../localization/useTranslation'
import { pretifyBigNum } from '../../utils'
import { USER_INDEXES } from '../../constants/appSettings'
import NotEnoughDataForChartMessage from './NotEnoughDataForChartMessage'
import { ChartName } from './chartsCommonWrappers'
import { themeCommonStyles } from '../../themes/infludataThemes'

const truncateString = (string = '', maxLength = 14) => {
  const splitStr = string?.split(' ')?.length >= 2 ? `${string?.split(' ')[0]}…` : string
  return splitStr.length > maxLength ? `${splitStr.substring(0, maxLength)}…` : splitStr
}

const Wrapper = styled.div`
  width: 100%;
  height: ${props =>
    props.isForPdf || props.customHeight ? (props.customHeight ? props.customHeight : '250px') : '320px'};
  max-width: 425px;

  ${props =>
    props.isDisabled &&
    `
    ${props.theme.disabled}
    opacity: 0.9;
  `}

  .disabled-chart-message__container {
    left: ${props => (!props.isForPdf ? '47%' : '55%')};
  }
`

const VerticalBarChartInfo = styled.div`
  margin-bottom: ${props => (props.isSmall ? '15px' : '25px')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  ${props => props.isForPdf && 'flex-wrap: wrap;'}
`

const Median = styled.div`
  width: ${props => (props.isForPdf ? '100%' : 'auto')};
  font-size: 15px;
  font-weight: bold;
  line-height: 19px;
  text-align: ${props => (props.isForPdf ? 'right' : 'left')};
  ${props => {
    if (props.isDisabled) return ''
    return props =>
      props.isForPdf
        ? `color: ${props.theme.getColorPaletteForPlatform(props.platform).main};`
        : props.theme.getTextGradientStyles({ platform: props.platform })
  }}
  text-transform: uppercase;
  ${props => props.isForPdf && 'white-space: nowrap;'}
`

const ChartWrapper = styled.div`
  height: ${props => (!props.isForPdf ? '80%' : '70%')};
  width: 100%;
  align-self: flex-end;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 25px;
    left: 0;
    height: 1px;
    width: 100%;
    background: ${props =>
      props.isDisabled ? props.theme.colors.lightMauve : props.theme.colors.mediumBrown};
    opacity: 0.7;
  }
`

const LabelWrapper = styled.div`
  font-size: ${props => (!props.isForPdf ? '13px' : '11px')};
  line-height: 16px;
  color: ${props => props.theme.colors.darkBrown};
  text-transform: uppercase;
  padding: 0 3px;

  ${props =>
    props.isDisabled &&
    `
    color: ${props.theme.colors.lightMauve};
    background: ${props.theme.colors.lightMauve};
    width: 50px;
    user-select: none;
  `}
`

const ItemWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  position: relative;
`

const Bar = styled.div`
  height: ${props => props.percent || 50}%;
  width: ${props => (!props.isForPdf ? '45px' : '30px')};

  background: ${props => {
    if (props.isStacked) return

    let bgGradient = props.theme.gradients.mainDeg(180)
    let bgDisabled = props.theme.colors.lightBrown
    if (props.platform === USER_INDEXES.tiktok) {
      bgGradient = props.theme.gradients.mainTiktokDeg(180)
    }
    return props.isDisabled || props.grayed ? bgDisabled : bgGradient
  }};

  ${props =>
    props.isStacked &&
    `background: ${props.platform === USER_INDEXES.tiktok ? props.theme.colors.aqua : props.theme.colors.yellow};
      cursor: pointer;
      &:after {
        width: 100%;
        height:${props.stackedLastVal}%;
        content:"";
        background: ${props.theme.colors.salmon};
        border-radius: 4px 4px 0 0;
        position: absolute;
        left: 0;
        top: 0;
      }
    `};
  border-radius: 4px 4px 0 0;
  position: relative;
  margin-bottom: 10px;
`

const BarTip = styled(LabelWrapper)`
  margin-bottom: 10px;
  ${props =>
    props.isDisabled &&
    `
    opacity: 0;
  `}
`

const ItemPercent = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  ${props => {
    if (props.isDisabled) return ''
    return props =>
      props.isForPdf
        ? `color: ${props.theme.getColorPaletteForPlatform(props.platform).main};`
        : props.theme.getTextGradientStyles({ platform: props.platform })
  }}
`

const ItemFollowersNumber = styled.div`
  font-weight: bold;
  font-size: 13px;
  line-height: 15px;
  color: ${props => props.theme.colors.darkBrown};
`
const TooltipWrapper = styled.div`
  position: absolute;
  top: 0;
  min-width: 150px;
  width: 100%;
  height: 80px;
  border-radius: 4px;
  border: 1px solid #784e4e;
  padding: 5px;
  color: #784e4e;
  font-weight: bold;
  background-color: white;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .tooltip-label {
    text-align: left;
  }
  .tooltip-main-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
`
const TooltipWrapperSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .square {
    background: ${props => props.squareColor};
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
`

const VerticalBarChart = ({
  chartData = [],
  title = '',
  userindex = '',
  median = '',
  isDisabledChart = true,
  notEnoughData = false,
  isForPdf = false,
  isSmall = false,
  customHeight = '',
}) => {
  const { labelStrings } = useTranslation()
  const isChartDisabled = isDisabledChart || notEnoughData
  const handleSmallValues = value => (value < 1 ? 1 : value) // for too small percentages (e.g.: 0.3%) to make them visible on the chart

  const [hoveredData, setHoveredData] = useState({})

  return (
    <Wrapper isDisabled={isChartDisabled} isForPdf={isForPdf} customHeight={customHeight}>
      <VerticalBarChartInfo isForPdf={isForPdf} isSmall={isSmall}>
        {title && <ChartName>{title}</ChartName>}
        {median && (
          <Median platform={userindex} isForPdf={isForPdf}>
            &#8960; {labelStrings.avg}. {median}
          </Median>
        )}
      </VerticalBarChartInfo>
      <ChartWrapper isDisabled={isChartDisabled} userindex={userindex} isForPdf={isForPdf}>
        {chartData?.map(item => {
          const isStacked =
            item.valueMale && item.valueFemale
          return (
            <ItemWrapper key={item.label}>
              {hoveredData.label === item.label && isStacked && (
                <TooltipWrapper>
                  <div className="tooltip-label">Age group {item.label}</div>
                  <div className="tooltip-main-wrapper">
                    <TooltipWrapperSection squareColor={themeCommonStyles.colors.salmon}>
                      <div className="square"></div>
                      <div>{item.valueFemale}% Female</div>
                    </TooltipWrapperSection>
                    <TooltipWrapperSection squareColor={userindex === USER_INDEXES.instagram ? themeCommonStyles.colors.yellow : themeCommonStyles.colors.aqua}>
                      <div className="square"></div>
                      <div>{item.valueMale}% Male</div>
                    </TooltipWrapperSection>
                  </div>
                </TooltipWrapper>
              )}

              <BarTip isDisabled={isChartDisabled}>
                <ItemPercent platform={userindex} isForPdf={isForPdf}>
                  {item.value}%
                </ItemPercent>
                {item.followersCount && (
                  <ItemFollowersNumber>({pretifyBigNum(item.followersCount)})</ItemFollowersNumber>
                )}
              </BarTip>

              <Bar
                percent={handleSmallValues(item.value)}
                platform={userindex}
                grayed={item.grayed}
                isDisabled={isChartDisabled}
                isForPdf={isForPdf}
                isStacked={isStacked}
                stackedFirstVal={item.valueMale}
                stackedLastVal={item.valueFemale}
                onMouseEnter={() => isStacked && setHoveredData(item)}
                onMouseLeave={() => isStacked && setHoveredData({})}
              />

              <LabelWrapper isDisabled={isChartDisabled} isForPdf={isForPdf}>
                {truncateString(item.label)}
              </LabelWrapper>
            </ItemWrapper>
          )
        })}
      </ChartWrapper>

      {notEnoughData && <NotEnoughDataForChartMessage platform={userindex} isForPdf={isForPdf} />}
    </Wrapper>
  )
}

VerticalBarChart.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      followersCount: PropTypes.number,
      label: PropTypes.string,
      grayed: PropTypes.bool,
    })
  ).isRequired,
  userindex: PropTypes.string,
  median: PropTypes.string,
  isDisabledChart: PropTypes.bool,
  notEnoughData: PropTypes.bool,
  isForPdf: PropTypes.bool,
  isSmall: PropTypes.bool,
  customHeight: PropTypes.string,
}

export default VerticalBarChart
