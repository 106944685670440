import React, { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import {
  isUserAuthenticatedSelector,
  selectedProfileSelector,
  userCredsLoadProgressSelector,
  userCredsSelector,
  userGrantSelector,
} from '../redux/selectors'

import ProfileContainer from '../components/influencerProfile/ProfileContainer'
import Footer from '../components/Footer'
import { NewProfilePageWrapper } from '../components/common/styledWrappers'
import LoadingSpinner from '../components/common/spinners/LoadingSpinner'
import NotFoundProfilePageTip from '../components/pagesTips/NotFoundProfilePageTip'
// import useTranslation from '../localization/useTranslation'
import styled from 'styled-components'
import { NAVBAR_FOOTER_SUM_HEIGHT } from '../constants/appSettings'
import { PlanInactiveModal } from '../new-ui/components/no-plan-modal/PlanInactiveModal'
import { NoPlanModal } from '../new-ui/components/no-plan-modal/NoPlanModal'

export const NoAuthWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.textColor};
  font-weight: bold;
  font-size: 16px;
`

const NewProfilePage = ({ profileId }) => {
  // const { labelStrings } = useTranslation()
  const { profile, isLoading, isProfileNotFound } = useSelector(selectedProfileSelector)
  const isPageLoading = useSelector(userCredsLoadProgressSelector)

  const { no_plan, is_self_service } = useSelector(userGrantSelector, shallowEqual) // enable_username_search,
  const isUserAuthenticated = useSelector(isUserAuthenticatedSelector)
  const { showNoPlanModal } = useSelector(userCredsSelector) || {}

  const isProfile = !!profile

  const isEverythingLoaded = useMemo(
    () => !isLoading && !isPageLoading && (isProfile || isProfileNotFound),
    [isLoading, isPageLoading, isProfile, isProfileNotFound]
  )

  const isNewProfileAlreadyLoaded = useMemo(
    () => isEverythingLoaded || (isProfile && profileId === profile._id),
    [profileId, isProfile, isEverythingLoaded, profile]
  )

  const isReloadingSameProfile = isProfile && profileId === profile._id && isLoading

  const isSpinnerHidden = isEverythingLoaded || isNewProfileAlreadyLoaded || isProfileNotFound

  if (no_plan && isUserAuthenticated) {
    return showNoPlanModal ? (
      <Box sx={{ height: `calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px)` }} />
    ) : (
      <Box sx={{ height: `calc(100vh - ${NAVBAR_FOOTER_SUM_HEIGHT}px)` }}>
        {is_self_service ? <NoPlanModal /> : <PlanInactiveModal />}
      </Box>
    )
  }

  return (
    <>
      {!isSpinnerHidden && <LoadingSpinner isTransparent showOnFullPage isOuterPageLoad />}

      {/* {!enable_username_search && isEverythingLoaded && isProfile && (
        <NoAuthWrapper>{labelStrings.isNoAuthViewProfilePageOrWithoutPlan}</NoAuthWrapper>
      )} */}

      {(isNewProfileAlreadyLoaded || isReloadingSameProfile) && (
        <NewProfilePageWrapper>
          <ProfileContainer profile={profile} />
          <Footer isFixedFooter={false} />
        </NewProfilePageWrapper>
      )}

      {isEverythingLoaded && isProfileNotFound && (
        <>
          <NotFoundProfilePageTip />
          <Footer isFixedFooter />
        </>
      )}
    </>
  )
}

NewProfilePage.propTypes = {
  profileId: PropTypes.string.isRequired,
}

export default memo(NewProfilePage)
