import React from 'react'
import useTranslation from '../../../localization/useTranslation'
import InfoTip from '../../common/tips/InfoTip'
import {
  SectionTitleWrapper,
  SectionTitleName,
  SectionTitleBorder,
} from '../commonProfilePageStyles'
import Chart, { useChart } from '../../../new-ui/components/chart'

const colorMap = {
  instagram: ['#FCE3C8', '#F68220', '#FAB57A', '#F2630D'],
  tiktok: ['#CFF7F4', '#6BE7DF', '#29CDC0', '#149D92'],
  default: ['#FEE2E2', '#EF4444', '#FCA5A5', '#B91C1C'],
}

const BoxPlotMedianViewsChart = ({ boxPlotData, platform, isForPdf = false }) => {
  if (!boxPlotData?.length) return null

  const { labelStrings } = useTranslation()
  const { medianViews = [], medianEngagement = [] } = boxPlotData[0]

  const transformData = data =>
    data?.map(item => ({
      ...item,
      x: `${item.x} (${item.count ?? 0})`,
    }))

  const viewsTransformed = transformData(medianViews)
  const engagementTransformed = transformData(medianEngagement)

  const colors = colorMap[platform] || colorMap.default

  const formatBigNumbers = val => {
    if (val >= 1e6) return (val / 1e6).toFixed(1) + 'M'
    if (val >= 1e3) return (val / 1e3).toFixed(1) + 'K'
    return val.toFixed(0)
  }

  const formatPercent = val => (val * 100).toFixed(1) + '%'

  const createCustomTooltip = isEngagement => ({ series, seriesIndex, dataPointIndex, w }) => {
    if (
      !w.globals.seriesCandleO[seriesIndex] ||
      typeof w.globals.seriesCandleO[seriesIndex][dataPointIndex] === 'undefined'
    ) {
      return '<div style="padding:8px;">No box-plot data</div>'
    }

    const [min, q1, median, q3, max] = [
      w.globals.seriesCandleO[seriesIndex][dataPointIndex],
      w.globals.seriesCandleH[seriesIndex][dataPointIndex],
      w.globals.seriesCandleM[seriesIndex][dataPointIndex],
      w.globals.seriesCandleL[seriesIndex][dataPointIndex],
      w.globals.seriesCandleC[seriesIndex][dataPointIndex],
    ]

    const categoryLabel = w.globals.labels[dataPointIndex] || ''
    const formatValue = isEngagement ? formatPercent : formatBigNumbers

    return `
      <div style="padding:8px;">
        <div><strong>${categoryLabel}</strong></div>
        <div><small>Min: ${formatValue(min)}</small></div>
        <div><small>Q1 (25%): ${formatValue(q1)}</small></div>
        <div><small><strong>Median: ${formatValue(median)}</strong></small></div>
        <div><small>Q3 (75%): ${formatValue(q3)}</small></div>
        <div><small>Max: ${formatValue(max)}</small></div>
      </div>
    `
  }

  const createChartOptions = (data, isEngagement) => ({
    chart: {
      type: 'boxPlot',
      height: 300,
      toolbar: { show: false },
    },
    title: { text: '', align: 'left' },
    grid: { padding: { top: -30 } },
    xaxis: {
      labels: {
        formatter: isEngagement ? formatPercent : formatBigNumbers,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '40%',
      },
      boxPlot: {
        colors: {
          upper: isEngagement ? colors[2] : colors[0],
          lower: isEngagement ? colors[3] : colors[1],
        },
      },
    },
    stroke: { colors: ['#333'] },
    tooltip: {
      shared: false,
      custom: createCustomTooltip(isEngagement),
    },
  })

  const viewsState = useChart({
    series: [{ name: 'Median Views', type: 'boxPlot', data: viewsTransformed }],
    options: createChartOptions(viewsTransformed, false),
  })

  const engagementState = useChart({
    series: [{ name: 'Median Engagement Rate', type: 'boxPlot', data: engagementTransformed }],
    options: createChartOptions(engagementTransformed, true),
  })

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
      {engagementState.series[0].data.length > 0 && (
        <>
          {!isForPdf && (
            <SectionTitleWrapper>
              <SectionTitleName platform={platform}>
                {labelStrings.engagementBoxPlotTitle(platform === 'instagram' ? 'Reels' : 'Videos')}
              </SectionTitleName>
              <InfoTip>
                <div dangerouslySetInnerHTML={{ __html: labelStrings.engagementBoxPlotTooltip }} />
              </InfoTip>
              <SectionTitleBorder isForPdf={isForPdf} />
            </SectionTitleWrapper>
        )}
        <Chart
          options={engagementState.options}
          series={engagementState.series}
          type="boxPlot"
          height={engagementState.series[0].data.length === 1 ? 150 : 300}
        />
      </>
      )}
      {viewsState?.series[0]?.data?.length > 0 && (
        <>
          {!isForPdf && (
            <SectionTitleWrapper>
              <SectionTitleName platform={platform}>
                {labelStrings.viewsBoxPlotTitle(platform === 'instagram' ? '(Reels)' : '')}
              </SectionTitleName>
              <InfoTip>
                <div dangerouslySetInnerHTML={{ __html: labelStrings.viewsBoxPlotTooltip }} />
              </InfoTip>
              <SectionTitleBorder isForPdf={isForPdf} />
            </SectionTitleWrapper>
          )}
          <Chart
            options={viewsState.options}
            series={viewsState.series}
            type="boxPlot"
            height={viewsState?.series[0]?.data?.length === 1 ? 150 : 300}
          />
        </>
      )}

    </div>
  )
}

export default BoxPlotMedianViewsChart
