import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useTranslation from '../../../localization/useTranslation'
import { withReduxActions } from '../../../hocs/withRedux'
import { toggleSignUp } from '../../../redux/ducks/appSettingsDucks'
import { userGrantSelector } from '../../../redux/selectors'
import { USER_INDEXES } from '../../../constants/appSettings'
//import { openHubSpotFormWidget } from '../../../utils/hubspot'
import { themeCommonStyles } from '../../../themes/infludataThemes'
import LinkToFaq from '../links/LinkToFaq'
import GradientTransparentButton from '../buttons/GradientTransparentButton'
import { Overlay, Title } from './styles'

const UnavailableProfileSection = ({
  unavailableSectionLabel = '',
  faqQuestionId = '',
  linkToFaqText = '',
  platform = '',
  toggleSignUp,
}) => {
  const { labelStrings } = useTranslation()
  const { no_plan: isNoPlan } = useSelector(userGrantSelector)

  let title = labelStrings.signup
  let buttonLabel = labelStrings.startTrial
  let onButtonClick = toggleSignUp

  if (isNoPlan) {
    title = labelStrings.activateYourAccount
    buttonLabel = labelStrings.contactSales
    //onButtonClick = openHubSpotFormWidget
  }

  return (
    <Overlay className="unavailable-section-overlay">
      <Title className="unavailable-section-overlay__title">
        {`${title}${unavailableSectionLabel}`}
      </Title>

      <LinkToFaq faqQuestionId={faqQuestionId}>{linkToFaqText}</LinkToFaq>
      <GradientTransparentButton
        className="unavailable-section-overlay__button"
        onClick={onButtonClick}
        isDisabled={false}
        backgroundColor={themeCommonStyles.colors.offWhite}
        gradient={themeCommonStyles.getGradientsForPlatform(platform).main}
        {...(platform
          ? { gradient: themeCommonStyles.getGradientsForPlatform(platform).main }
          : {})}
      >
        {buttonLabel}
      </GradientTransparentButton>
    </Overlay>
  )
}

UnavailableProfileSection.propTypes = {
  unavailableSectionLabel: PropTypes.string,
  faqQuestionId: PropTypes.string,
  linkToFaqText: PropTypes.string,
  platform: PropTypes.oneOf([USER_INDEXES.instagram, USER_INDEXES.tiktok, USER_INDEXES.youtube]),
  toggleSignUp: PropTypes.func,
}

export default withReduxActions({ toggleSignUp })(UnavailableProfileSection)
